import { Route, Routes, useLocation } from "react-router-dom";
import UserPanelRoutes from "./UserPanelRoutes";
import HeaderPhone from "./components/Header/HeaderPhone";
import AdminRouterLayout from "./adminPanel/components/adminRouter/AdminRouterLayout";
import { useRef, useState } from "react";
import TmpPopup from "./tmpPopup/TmpPopup";
import Landing from "./pages/LandingPage/Landing";
import CustomsRules from "./pages/Information/CustomsRules";
import ForbiddenParcels from "./pages/Information/ForbiddenParcels";
import Header2 from "./components/Header/Header2";
import Footer2 from "./components/Footer/Footer2";
import Instructions from "./pages/Instructions/Instructions";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Popup from "./tmpPopup/Popup";
import RegistrationSuccessful from "./components/landingSections/firstSection/RegistrationSuccessful";

const MainRouter = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [info, setInfo] = useState(true);

  const menuRefs = {
    aboutUs: useRef(null),
    contact: useRef(null),
  };

  const [tmpPop, setTmpPop] = useState(true);

  return (
    <>
      {/* {info && location.pathname.split("/")[1] !== "admin" && (
        <div className="bg-teal_600 flex justify-between items-center px-4 py-2">
          <div className="sm:hidden"></div>
          <p className="font-bold text-white text-sm sm:text-xs">
            🎁 {t("giftText")}
          </p>
          <BsX
            className="text-white text-2xl cursor-pointer"
            onClick={() => {
              setInfo(false);
            }}
          />
        </div>
      )} */}

      {/* {location.pathname.split("/")[1] !== "admin" && <Popup />} */}

      {location.pathname.split("/")[1] !== "personal-cabinet " &&
        location.pathname.split("/")[1] !== "admin" && (
          <Header2 menuRefs={menuRefs} />
        )}
      {location.pathname.split("/")[1] !== "personal-cabinet" &&
        location.pathname.split("/")[1] !== "admin" && (
          <HeaderPhone menuRefs={menuRefs} />
        )}

      <Routes>
        <Route path="/" element={<Landing menuRefs={menuRefs} />} />
        <Route
          path="registration-success"
          element={<RegistrationSuccessful />}
        />

        <Route
          path="customs-information"
          element={<CustomsRules menuRefs={menuRefs} />}
        />
        <Route
          path="forbidden-parcels"
          element={<ForbiddenParcels menuRefs={menuRefs} />}
        />

        <Route
          path="instructions"
          element={<Instructions menuRefs={menuRefs} />}
        />

        <Route path="admin/*" element={<AdminRouterLayout />} />

        <Route path="personal-cabinet/*" element={<UserPanelRoutes />} />
      </Routes>

      {!location.pathname.includes("personal-cabinet") &&
        location.pathname.split("/")[1] !== "admin" && <Footer2 />}

      {tmpPop && location.pathname.split("/")[1] !== "admin" && (
        <TmpPopup setTmpPop={setTmpPop} />
      )}
    </>
  );
};

export default MainRouter;
