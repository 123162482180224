import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import FullNavigation from "../navigations";
import LoginLayout from "../pages/account/login";
import UsLayout from "../pages/users/usLayout";
import InnerUsPage from "../pages/users/InnerUsPage";
import UserPageLayout from "../pages/AdminsPage/userPageLayout";
import InnerUsersPage from "../pages/AdminsPage/innerUsersPage";
import FaqLayout from "../pages/faq/FaqLayout";
import InnerFaqPage from "../pages/faq/innerFaqPage";
import Countries from "../pages/countries/Countries";
import InnerCountries from "../pages/countries/InnerCountries";
import AbOfficesLayout from "../pages/AbOffices/AbOfficesLayoutLayout";
import InnerAbOfficesPage from "../pages/AbOffices/innerAbOfficesPage";
import FilialLayout from "../pages/Filials/FilialLayout";
import InnerFilialPage from "../pages/Filials/InnerFilialPage";
import Flights from "../pages/flights/Flights";
import InnerFlightsPage from "../pages/flights/InnerFlightsPage";
import Archive from "../pages/archive/Archive";
import WarehousePackage from "../pages/Packages/InWarehouse/WarehousePackage";
import SentPackage from "../pages/Packages/SentPackages/SentPackage";
import AppeardPackages from "../pages/Packages/AppeardPackages/AppeardPackages";
import PassedPackages from "../pages/Packages/PassedPackages/PassedPackages";

import { AdminPrimaryContext } from "../adminContext/AdminContext";

import "react-toastify/dist/ReactToastify.css";
import Support from "../pages/support/Support";
import PanelMessages from "../pages/messages/PanelMessages";
import SendMessagePanel from "../pages/messages/SendMessagePanel";
import DeclarationService from "../pages/declarationService/DeclarationService";
import ExportsPage from "../pages/ExportsPage/ExportsPage";
import PopupsUpdate from "../pages/popups/PopupsUpdate";
import Popups from "../pages/popups/Popups";

const AdminRouter = () => {
  //get current page url
  const location = useLocation();
  //correct navigation
  const navigate = useNavigate();
  //check if user is authorized
  const { isAuthorized, user } = useContext(AdminPrimaryContext);

  //correct navigation
  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("login");
    }
  }, []);

  return (
    <div>
      {isAuthorized && location.pathname !== "/admin/login" && (
        <FullNavigation />
      )}
      <Routes>
        <Route path="login" element={<LoginLayout />} />
        {isAuthorized && (
          <>
            {user.role === "100" && (
              <>
                <Route path="users" element={<UsLayout />} />
                <Route path="users/:siteUsersId" element={<InnerUsPage />} />

                <Route path="admins" element={<UserPageLayout />} />
                <Route path="admins/:usersId" element={<InnerUsersPage />} />

                <Route path="faq" element={<FaqLayout />} />
                <Route path="faq/:faqId" element={<InnerFaqPage />} />

                <Route path="countries" element={<Countries />} />
                <Route
                  path="countries/:countryId"
                  element={<InnerCountries />}
                />

                <Route path="ab-office" element={<AbOfficesLayout />} />
                <Route
                  path="ab-office/:officeId"
                  element={<InnerAbOfficesPage />}
                />

                <Route path="filial" element={<FilialLayout />} />
                <Route path="filial/:filialId" element={<InnerFilialPage />} />

                <Route path="popup" element={<Popups />} />
                <Route path="popup/:popupId" element={<PopupsUpdate />} />

                <Route path="flights" element={<Flights />} />
                <Route
                  path="flights/:flightsId"
                  element={<InnerFlightsPage />}
                />

                <Route path="archive" element={<Archive />} />

                <Route path="in-warehouse" element={<WarehousePackage />} />
                <Route path="sent" element={<SentPackage />} />
                <Route path="appeard" element={<AppeardPackages />} />
                <Route path="deliverd" element={<PassedPackages />} />

                <Route path="support" element={<Support />} />
                <Route path="messages" element={<PanelMessages />} />
                <Route path="messages/:userId" element={<SendMessagePanel />} />

                <Route
                  path="declaration-service"
                  element={<DeclarationService />}
                />
              </>
            )}

            {(user.role === "1" || user.role === "2" || user.role === "3") && (
              <>
                <Route path="in-warehouse" element={<WarehousePackage />} />
              </>
            )}

            <Route path="by-flights" element={<ExportsPage />} />

            {/* <Route path="admin-page" element={<EditProfile />} /> */}
          </>
        )}
      </Routes>
    </div>
  );
};

export default AdminRouter;
