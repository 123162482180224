import { useTranslation } from "react-i18next";
import { FaRegClock } from "react-icons/fa6";

const WorkingHoursCard = () => {
  const { t } = useTranslation();

  return (
    <div className="px-12 md:px-6 w-full flex flex-col items-center justify-center relative">
      <div className="flex flex-col font-helvetica gap-4 items-center justify-start w-full">
        <h6 className="text-black_900 text-2xl md:text-xl font-bold">
          {t("working_hours")}
        </h6>
      </div>

      <div className="grid grid-cols-2 gap-5 w-full mt-12 md:mt-6 sm:grid-cols-1 max-w-[80%] md:max-w-[100%]">
        <div className="border-2 border-[#F0F0F0] rounded-2xl p-5 w-full flex flex-col gap-3">
          <div className="flex items-center justify-center w-full">
            <FaRegClock className="text-teal_600 text-3xl" />
          </div>
          <p className="text-base text-center font-bold">{t("batumi_poti")}</p>

          <p className="text-xs text-center font-bold">
            {t("monday_friday")} 09:00 - 18:00
          </p>
        </div>

        <div className="border-2 border-[#F0F0F0] rounded-2xl p-5 w-full flex flex-col gap-3">
          <div className="flex items-center justify-center w-full">
            <FaRegClock className="text-teal_600 text-3xl" />
          </div>
          <p className="text-base text-center font-bold">{t("tbilisi")}</p>

          <p className="text-xs text-center  font-bold">
            {t("monday_friday")} 09:00 - 21:00
          </p>
          <p className="text-xs text-center font-bold">{t("saturday")} 11:00 - 15:00</p>
        </div>
      </div>
    </div>
  );
};

export default WorkingHoursCard;
